/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';
import TopLayout from './TopLayout';

/* lazysizes support for image load */
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

export const wrapRootElement = ({ element }) => {
    return <TopLayout>{element}</TopLayout>;
};