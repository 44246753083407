import React, { useEffect } from "react"
import i18next from "@src/site/locales"
import { I18nextProvider } from "react-i18next"
import { window } from "browser-monads"
import { getLanguageByAlpha2 } from "@src/utils/country"

export const detectLanguage = url => {
  let location
  if (typeof url !== "undefined") {
    location = url // SSE support
  } else {
    location = window.location.pathname // Browser support
  }
  let path = location.split("/")
  let cleanup = []
  let lang = process.env.I18N_FALLBACK_LANGUAGE
  for (let i = 0; i < path.length; i++) {
    if (path[i] !== "") {
      cleanup.push(path[i])
    }
  }
  if (cleanup.length >= 1) {
    let country = cleanup[0]
    let check = getLanguageByAlpha2(country)
    if (check) {
      lang = check
    }

    if (country === "us") {
      lang = "en_US"
    }
    if (country === "at") {
      lang = "de_AT"
    }
  }

  // Set language
  i18next.changeLanguage(lang)
  //console.warn("[i18n] Set Language", location, lang)

  return {
    lang: lang,
    location: location,
  }
}

export default function I18nProvider({ children, url }) {
  detectLanguage(url)

  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
}
