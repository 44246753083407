import { window } from "browser-monads"

const alpha2LocaleMap = {
  UK: "en-GB",
  GB: "en-GB",
  DE: "de-DE",
  US: "en-US",
  IT: "it-IT",
  AT: "de-AT",
  NL: "nl-NL",
}
export function getLocaleByAlpha2(alpha2) {
  if (alpha2) {
    return alpha2LocaleMap[alpha2.toUpperCase()]
  }
  return false
}

export function getLanguageByAlpha2(alpha2) {
  if (alpha2) {
    let locale = getLocaleByAlpha2(alpha2)
    if (locale) {
      return locale.substring(0, 2)
    }
  }
  return false
}

export function detectAlpha2CountryInPath(path) {
  path = path.replace(/\/+$/, "") + "/"
  const country = path.match(/\/([a-zA-Z]){2}\//g)
  if (country && country.length > 0) {
    return country[0].replace(/\//g, "")
  }
  return "uk"
}

export function detectAlpha2Country() {
  return detectAlpha2CountryInPath(window.location.pathname)
}

export function detectLocale() {
  const country = detectAlpha2Country()
  const locale = getLocaleByAlpha2(country)
  return locale || ""
}

export const detectAlpha2LanguageInPath = path => {
  const country = detectAlpha2CountryInPath(path || window.location.pathname)
  return getLanguageByAlpha2(country)
}

export const detectAlpha2Language = () => {
  const country = detectAlpha2Country()
  return getLanguageByAlpha2(country)
}

export async function detectBrowserLanguage() {
  let language = window?.navigator?.language || window?.navigator?.userLanguage
  let country =
    language.indexOf("-") > -1
      ? language?.split("-")?.shift()?.toUpperCase()
      : ""
  return {
    country: country,
    language: language,
  }
}
