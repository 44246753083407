// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-confirm-cancellation-js": () => import("./../../../src/pages/confirm-cancellation.js" /* webpackChunkName: "component---src-pages-confirm-cancellation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-start-cancellation-js": () => import("./../../../src/pages/start-cancellation.js" /* webpackChunkName: "component---src-pages-start-cancellation-js" */),
  "component---src-pages-web-map-js": () => import("./../../../src/pages/WebMap.js" /* webpackChunkName: "component---src-pages-web-map-js" */),
  "component---src-snake-render-js": () => import("./../../../src/snake/render.js" /* webpackChunkName: "component---src-snake-render-js" */)
}

