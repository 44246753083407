import { createMuiTheme } from "@material-ui/core/styles"

const defaultTheme = createMuiTheme({
  spacing: 0,
})

const WHITE = "#fff"
const BLACK = "#000"
const fallbackFont = ", 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif"

const fonts = {
  faces: {
    specialTitleXXL: {
      fontFamily: "Druk Wide Web" + fallbackFont,
      //fontSize: 'min(max(32px, 2.5vw), 40px)',
      fontSize: "2.5rem !important",
      lineHeight: "2.4rem !important",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.8rem !important",
        lineHeight: "1.7rem !important",
      },
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    specialTitleXL: {
      fontFamily: "Druk Wide Web" + fallbackFont,
      //fontSize: 'min(max(27px, 2.1vw), 32px)',
      fontSize: "2rem !important",
      lineHeight: "1.9rem !important",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.5rem !important",
        lineHeight: "1.43rem !important",
      },
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    specialTitleL: {
      fontFamily: "Druk Wide Web" + fallbackFont,
      //fontSize: 'min(max(27px, 2.1vw), 32px)',
      fontSize: "1.6rem !important",
      lineHeight: "1.3rem !important",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.2rem !important",
        lineHeight: "1.13rem !important",
      },
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    titleExtXXXL: {
      fontFamily: "SpeziaExtended" + fallbackFont,
      //fontSize: 'min(max(20px, 1.6vw), 24px)',
      fontSize: "2.5rem !important",
      lineHeight: "normal",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "2rem !important",
      },
    },
    titleXXXL: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(36px, 1.7vw), 8.6vw)',
      //fontSize: '36px',
      fontSize: "2.2rem !important",
      lineHeight: "2.1rem !important",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "2rem !important",
        lineHeight: "1.93rem !important",
      },
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    titleXXL: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(18px, 2vw), 30px)',
      //fontSize: 'min(max(22px, 2vw), 7.5vw)',
      fontSize: "1.9rem !important",
      lineHeight: "1.8rem !important",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.7rem !important",
        lineHeight: "1.6rem !important",
      },
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    titleExtXL: {
      fontFamily: "SpeziaExtended" + fallbackFont,
      //fontSize: 'min(max(20px, 1.6vw), 24px)',
      fontSize: "1.7rem !important",
      lineHeight: "normal",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1rem !important",
      },
    },
    titleXL: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(20px, 1.6vw), 24px)',
      fontSize: "1.5rem !important",
      lineHeight: "1.7rem !important",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "1.1rem !important",
        lineHeight: "1.5rem !important",
      },
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    titleL: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(15px, 1.2vw), 18px)',
      //fontSize: 'min(max(18px, 1.5vw), 5.8vw)',
      fontSize: "1.2rem !important",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal !important",
      letterSpacing: "normal",
    },
    title: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(11px, 0.9vw), 14px)',
      //fontSize: 'min(max(16px, 1.4vw), 5.3px)',
      fontSize: "1.2rem !important",
      fontWeight: "500",
      fontStyle: "normal",
      letterSpacing: 0,
      lineHeight: "normal !important",
    },
    bodyExtXXL: {
      fontFamily: "SpeziaExtended" + fallbackFont,
      fontSize: "1.9rem !important",
      lineHeight: "normal",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    bodyXXL: {
      fontFamily: "Spezia" + fallbackFont,
      fontSize: "1.9rem !important",
      lineHeight: "2.5rem !important",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    bodyXL: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(20px, 1.6vw), 24px)',
      fontSize: "1.5rem !important",
      lineHeight: "2.1rem !important",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    bodyExtL: {
      fontFamily: "SpeziaExtended" + fallbackFont,
      //fontSize: 'min(max(15px, 1.2vw), 18px)',
      //fontSize: 'min(max(18px, 1.5vw), 5.8vw)',
      fontSize: "1.3rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "2.84 !important",
      letterSpacing: "normal",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "0.62rem !important",
      },
    },
    bodyL: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(15px, 1.2vw), 18px)',
      //fontSize: 'min(max(18px, 1.5vw), 5.8vw)',
      fontSize: "1.2rem !important",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal !important",
      letterSpacing: "normal",
    },
    bodyExtM: {
      fontFamily: "SpeziaExtended" + fallbackFont,
      //fontSize: '16px',
      //fontSize: 'min(max(16px, 1.4vw), 5.3vw)',
      fontSize: "1.1rem !important",
      lineHeight: "1.6rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "0.75rem !important",
      },
    },
    bodyM: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: '16px',
      //fontSize: 'min(max(16px, 1.4vw), 5.3vw)',
      fontSize: "1.1rem !important",
      lineHeight: "1.6rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    bodyLhM: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: '16px',
      //fontSize: 'min(max(16px, 1.4vw), 5.3vw)',
      fontSize: "1.1rem !important",
      lineHeight: "1.25 !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    bodyExtS: {
      fontFamily: "SpeziaExtended" + fallbackFont,
      //fontSize: 'min(max(15px, 1.2vw), 18px)',
      //fontSize: 'min(max(18px, 1.5vw), 5.8vw)',
      fontSize: "1rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      [defaultTheme.breakpoints.down("sm")]: {
        fontSize: "0.56rem !important",
      },
    },
    bodyS: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(11px, 0.9vw), 14px)',
      //fontSize: 'min(max(15px, 1.2vw), 3.8vw)',
      fontSize: "1rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "0.95rem !important",
      letterSpacing: "normal",
    },
    bodyXS: {
      fontFamily: "Spezia" + fallbackFont,
      //fontSize: 'min(max(10px, 0.8vw), 12px)',
      fontSize: "0.76rem !important",
      lineHeight: "0.66rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
    },
    bodyXXS: {
      fontFamily: "Spezia" + fallbackFont,
      fontSize: "0.7rem !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
    },
  },
  weights: {
    medium: {
      fontWeight: 500,
    },
    lightBold: {
      fontWeight: 600,
    },
    bold: {
      fontWeight: 700,
    },
  },
}

const customPalette = {
  darkishPurple: "#662482",
  grapePurple: "#492062",
  lightEggplant: "#714b8d",
  grapePurpleTwo: "#341349",
  fadedPurple: "#8a6ca2",
  eggplant: "#210932",
  wisteria: "#a993b9",
  veryDarkPurple: "#16001e",
  palePurple: "#baaac7",
  paleLavender: "#e5dfe8",
  paleMauve: "#f3eef3",
  tangerine: "#f29100",
  brownishOrange: "#be7425",
  milkChocolate: "#91581d",
  milkChocolateTwo: "#6d4218",
  mudBrown: "#432a0e",
  fadedOrange: "#efa050",
  apricot: "#f3b674",
  beige: "#f8cd9a",
  lightPeach: "#f9d8ae",
  offWhite: "#fff1de",
  veryLightPink: "#fff8ed",
  black: "#000000",
  900: "#222222",
  charcoal: "#4d4e4c",
  mediumGrey: "#777776",
  greenishGrey: "#9e9f9e",
  lightGreyGreen: "#b4b5b4",
  silver: "#cbcccb",
  greyClear: "#f0f0f0",
  coral: "#f93f3f",
  vibrantGreen: "#15ce0a",
  primaryGrey05: "#f8f8f8",
  tundora: "#454545",
  radicalRed: "#ff2765",
  greyLight: "#b1b1b1",
  darkGrey: "#494949"  
}

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    primary: {
      main: customPalette.darkishPurple,
    },
    secondary: {
      main: customPalette.tangerine,
    },
    error: {
      main: customPalette.coral,
    },
    success: {
      main: customPalette.vibrantGreen,
    },
    text: {
      primary: BLACK,
      secondary: customPalette.darkishPurple,
      tertiary: WHITE,
    },
  },
  fonts,
  customPalette,
  /*
   * FH components
   */
  button: {
    big: {
      ...fonts.faces.bodyM,
      padding: defaultTheme.spacing(1, 2, 1, 2),
    },
    medium: {
      ...fonts.faces.bodyM,
      padding: "4px 16px 4px 16px",
    },
    small: {
      ...fonts.faces.bodyS,
      padding: "0px 16px 0px 16px",
    },
  },
  text: {
    medium: {
      fontWeight: "500 !important",
    },
    bold: {
      fontWeight: "700 !important",
    },
    ...fonts.faces,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: "#fff",
        },
        ".ch2-dialog": {
          zIndex: "7000000 !important",
        },
        ".ch2-icon": {
          display: "none!important",
        },
        "#launcher": {
          bottom: "80px !important",
        },
        p: {
          marginBlockStart: 0, // FIX ** reset padding for unhandled p tags from cms
          marginBlockEnd: 0,
        },
        a: {
          color: customPalette.tangerine,
          ...fonts.weights.bold,
          "&:hover": {
            color: customPalette.brownishOrange,
          },
          textDecoration: "none",
        },
        ".moduleTitle": {
          marginBottom: defaultTheme.spacing(3),
          padding: defaultTheme.spacing(0, 2),
        },
        ".moduleTitle::before": {
          content: '""',
          width: 50,
          display: "block",
          height: 4,
          borderRadius: 2,
          backgroundColor: customPalette.darkishPurple,
          margin: "auto",
          marginBottom: defaultTheme.spacing(3),
        },
        ".specialTitleXXL": fonts.faces.specialTitleXXL,
        ".specialTitleXL": fonts.faces.specialTitleXL,
        ".specialTitleL": fonts.faces.specialTitleL,
        ".titleExtXXXL": fonts.faces.titleExtXXXL,
        ".titleXXXL": fonts.faces.titleXXXL,
        ".titleXXL": fonts.faces.titleXXL,
        ".titleExtXL": fonts.faces.titleExtXL,
        ".titleXL": fonts.faces.titleXL,
        ".titleL": fonts.faces.titleL,
        ".bodyExtXXL": fonts.faces.bodyExtXXL,
        ".bodyXXL": fonts.faces.bodyXXL,
        ".bodyXL": fonts.faces.bodyXL,
        ".bodyExtL": fonts.faces.bodyExtL,
        ".bodyL": fonts.faces.bodyL,
        ".bodyExtM": fonts.faces.bodyExtM,
        ".bodyM": fonts.faces.bodyM,
        ".bodyLhM": fonts.faces.bodyLhM,
        ".bodyExtX": fonts.faces.bodyExtS,
        ".bodyS": fonts.faces.bodyS,
        ".bodyXS": fonts.faces.bodyXS,
        ".purple": {
          color: customPalette.darkishPurple,
        },
        ".white": {
          color: "#fff",
        },
        ".medium": fonts.weights.medium,
        ".bold": fonts.weights.bold,
        // tables
        "table,th,td": {
          border: "4px solid #f0f0f0",
          borderCollapse: "collapse",
          padding: "20px 16px",
          ...fonts.faces.bodyS,
        },
        td: {
          borderWidth: "1px",
          fontFamily: fallbackFont,
        },
        th: {
          backgroundColor: "#f0f0f0",
          ...fonts.faces.bodyM,
          ...fonts.weights.bold,
        },
      },
    },
    // Style sheet name ⚛️
    MuiContainer: {
      root: {
        //paddingLeft: defaultTheme.spacing(1), // Apply padding reset
        //paddingRight: defaultTheme.spacing(1) // Apply padding reset
        padding: "0 !important", // Padding reset
        width: "auto", // Width reset
      },
    },
    MuiButton: {
      root: {
        background: customPalette.tangerine,
        color: "white",
        padding: defaultTheme.spacing(1 / 2, 2, 1 / 2, 2),
        textTransform: "capitalize",
        borderRadius: 2,
        backgroundColor: customPalette.tangerine,
        ...fonts.faces.bodyS,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.18)",
        "&:hover": {
          backgroundColor: customPalette.brownishOrange,
          color: "white",
          boxShadow: "none",
          outline: "none",
        },
        "&:focus": {
          outline: "none",
        },
      },
      outlined: {
        padding: "10px 25px",
        textTransform: "initial",
        color: BLACK,
        borderColor: BLACK,
      },
      // Name of the rule
      contained: {
        outline: "0 !important",
        borderRadius: 2,
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.18)",
        fontSize: 14,
        fontWeight: 500,
        height: 40,
        textTransform: "initial",
      },
      containedSecondary: {
        color: WHITE,
        "&:hover": {
          backgroundColor: customPalette.brownishOrange,
        },
      },
      sizeSmall: {
        fontSize: 14,
        fontWeight: 500,
        height: 32,
      },
      sizeLarge: {
        fontSize: 16,
        ...fonts.faces.bodyM,
        fontWeight: 500,
        height: 48,
      },
    },
    MuiRadio: {
      root: {
        color: customPalette.tangerine,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: customPalette.radicalRed,
        color: "white",
        height: 19,
        fontSize: 12,
        ...fonts.bodyS,
        padding: defaultTheme.spacing(1 / 2),
        lineHeight: 1.27,
        borderRadius: 0,
      },
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      colorSecondary: {
        color: WHITE,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "unset",
        paddingRight: defaultTheme.spacing(2),
      },
    },
    MuiFilledInput: {
      root: {
        height: 48,
        backgroundColor: customPalette.primaryGrey05,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        borderRadius: 2,
        borderBottom: `2px solid ${customPalette.silver}`,
        "&$focused": {
          borderBottom: `2px solid ${customPalette.tangerine}`,
        },
        "&$disabled": {
          backgroundColor: customPalette.primaryGrey05,
          color: "#b1b1b1",
        },
        "&$error": {
          borderBottom: `2px solid ${customPalette.coral}`,
        },
      },
      input: {
        padding: "22px 12px 6px",
      },
      underline: {
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },
    },
  },
})

export default theme
